<template >
  <v-app>
    <v-container class="my-6 mx-auto">
      <v-card class="py-10">
        <div class="card-header-padding">
          <v-row class="mb-4">
            <v-col cols="auto">
              <div>
                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                  Ангиудын xичээл xувиарлалт
                </h5>
                <p class="text-sm text-body mb-0">
                  Ангиудад ямар xичээл ороxыг xуваарилаx
                </p>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="card-header-padding">
          <v-simple-table class="bborder">
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    style="
                      border-right: 0px !important;
                      border-left: 1px solid #bbb;
                    "
                  >
                    No
                  </th>
                  <th>Нэр</th>
                  <th>Төрөл</th>
                  <th
                    v-for="dep in departments"
                    :key="dep.id"
                    class="text-center font-weight-bold"
                  >
                    {{ dep.name }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(lesson, lindex) in allLessons"
                  :key="lesson.id + lindex"
                >
                  <td
                    style="
                      border-right: 0px !important;
                      border-left: 1px solid #bbb;
                      width: 1%;
                    "
                  >
                    {{ lindex + 1 }}
                  </td>
                  <td>{{ lesson.name }}</td>
                  <td>
                    {{ lesson.lessonTypeName }}
                  </td>
                  <template v-for="dep in departments">
                    <td
                      :key="lesson.id + lindex + dep.id"
                      class="text-center borderCell"
                      @click="_clickedCell(dep, lesson)"
                      v-if="_isContained(dep, lesson)"
                      style="background: #f5f591; cursor: pointer"
                      :title="dep.name"
                    >
                      <span>+</span>
                    </td>
                    <td
                      class="text-center"
                      :key="'xxa'+lesson.id + lindex + dep.id"
                      v-else
                      @click="_clickedCell(dep, lesson)"
                      :title="dep.name"
                      style="cursor: pointer"
                    >
                      -
                    </td>
                  </template>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
export default {
  data() {
    return {
      school: null,
      departments: null,
      allLessons: null,
    };
  },

  methods: {
    _isContained(dep, lesson) {
      if (lesson.departments) {
        for (const dep2 of lesson.departments) {
          if (dep.id == dep2.id) {
            return true;
          }
        }
      }
      return false;
    },
    _clickedCell(dep, lesson) {
      var added = this._isContained(dep, lesson);
      if (added) {
        lesson.ref
          .collection("departments-" + this.userData.school.currentYear)
          .doc(dep.id)
          .delete()
          .then(() => {
            dep.ref.collection("lessons").doc(lesson.id).delete();
          });
      } else {
        lesson.ref
          .collection("departments-" + this.userData.school.currentYear)
          .doc(dep.id)
          .set(
            { name: dep.name, index: dep.index, ref: dep.ref },
            { merge: true }
          )
          .then(() => {
            dep.ref
              .collection("lessons")
              .doc(lesson.id)
              .set(lesson, { merge: true });
          });
      }
    },

    // _clickedCell(lesson, prog) {
    //   if (this.changeTeacher) {
    //     this.selectedProgram = Object.assign({}, prog);
    //     //$("#proModal").modal("toggle");TODO
    //   } else {
    //     this.$router.push({
    //       name: "IGroupStudents",
    //       params: {
    //         schoolId: this.school.id,
    //         departmentId: lesson.id,
    //         programId: prog.id,
    //       },
    //     });
    //   }
    // },

    async _setupp() {
      var query;

      // if (fb.EYESH_APP) {
        query = fb.db.collection("categories");
      // } else {
      //   query = this.userData.school.ref.collection("categories");
      // }
      query.orderBy("name", "asc").onSnapshot((querySnapshot) => {
        this.allLessons = [];
        querySnapshot.forEach((doc) => {
          let lesson = doc.data();
          lesson.id = doc.id;
          lesson.ref = doc.ref;
          lesson.departments = null;
          lesson.ref
            .collection("departments-" + this.userData.school.currentYear)
            .onSnapshot((querySnapshot) => {
              lesson.departments = [];
              querySnapshot.forEach((doc) => {
                let dep = doc.data();
                dep.id = doc.id;
                dep.ref = doc.ref;
                lesson.departments.push(dep);
              });
            });
          this.allLessons.push(lesson);
        });
      });
      this._getDepartments();
    },
    _getDepartments() {
      this.userData.school.ref
        .collection("departments")
        .where("startYear", "==", this.userData.school.currentYear)
        .orderBy("index", "asc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          querySnapshot.forEach((doc) => {
            let lesson = doc.data();
            lesson.id = doc.id;
            lesson.ref = doc.ref;
            this.departments.push(lesson);
          });
        });
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },

  created() {
    if (this.userData.school) this._setupp();
  },
};
</script>

<style>
.bborder td {
  border-right: 1px solid #bbb !important;
}
.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}
.bborder .borderCell:hover {
  background-color: red;
}
</style>